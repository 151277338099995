<template>
  <div class="row p-tb-1-5">
    <div class="col-lg-3 m-auto">
      <h1 class="text-center">Sign in</h1>
      <div class="login p-25px rounded-5 bordered-light">
        <form @submit.prevent="login" @input="failed = false">
          <div class="m-10px" style="font-size: 1.5em; color: #f30000">
            <div v-if="failed">{{ failMessage }}</div>
          </div>
          <div class="m-10px">
            <label for="email">Email</label>
            <input class="mt-1" id="email" required v-model="username" type="email" placeholder="Email" />
          </div>
          <div class="m-10px">
            <label for="password">Password</label>
            <input class="mt-1" id="password" required v-model="password" type="password" placeholder="Password" />
          </div>
          <div class="m-10px p-t-1">
            <div class="m-r-1 rounded-5">
              <button
                :class="{ 'loading-bg': loading }"
                class="text-medium medium-font button text-color-white bg-light-green"
                type="submit"
                :disabled="loading"
              >
                {{ loading ? 'Logging In' : 'Login' }}
              </button>
            </div>
          </div>
        </form>
        <div class="hr" style="margin: 26px 10px" />
        <div class="text-center">
          <router-link to="/register">Register for an account</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      failMessage: '',
      failed: false,
    };
  },
  methods: {
    login() {
      const { username, password } = this;
      this.loading = true;
      this.$client
        .auth({ username, password })
        .then(async token => {
          this.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const user = await this.$api.getUserProfile();
          this.loading = false;
          this.$store
            .dispatch('login', { success: !!token, token, user })
            .then(() => {
              return this.$router.push(this.$route.query.redirect || '/');
            })
            .catch(err => console.error(err));
        })
        .catch(err => {
          let message;
          if (err.response?.data) {
            const { message: errMessage } = err.response.data;
            message = `Error: ${errMessage}`;
          }
          this.failMessage = message || err.message || 'Something went wrong';
          this.failed = true;
          this.loading = false;
        });
    },
  },
};
</script>
