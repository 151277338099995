<template>
  <div v-if="succeed">
    <div class="row">
      <div class="col-lg-6 col-lg-offset-3">
        <div class="mt-5 mb-10 mx-2" style="font-size: 1rem">
          <h2>Congratulations!</h2>
          <p>You are successfully created the new <strong>VaporFly</strong> account.</p>
          <p>
            We are sent an email with your credentials to the <code>{{ form.email }}</code>
          </p>
        </div>
        <div class="mx-2">
          <div class="m-r-1 rounded-5">
            <router-link to="/login" class="text-medium medium-font button text-color-white bg-light-green">
              Go to the Log In page
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row p-tb-1-5 register-form">
    <div class="col-lg-3 m-auto">
      <h1 class="text-center">Register</h1>
      <div class="login p-25px rounded-5 bordered-light">
        <form @submit.prevent="submit">
          <div class="col-lg-12 m-10px" style="font-size: 1.5em; color: #f30000">
            <div v-if="failed">{{ failMessage }}</div>
          </div>
          <template v-for="(field, i) in fields">
            <FormField :error="getError(field.name)" class="col-lg-12 m-10px" :key="i">
              <template v-slot>
                <span>{{ field.label }}</span>
                <DropDownList
                  v-model="form[field.name]"
                  v-if="field.type === 'select'"
                  class="type-select mt-1"
                  :items="field.items"
                  :placeholder="field.placeholder"
                  v-bind="field.attrs"
                  @input="() => onInput(field.name)"
                />
                <input
                  v-else
                  v-model="form[field.name]"
                  class="mt-1"
                  :required="field.required"
                  :type="field.type"
                  :placeholder="field.placeholder"
                  @input="() => onInput(field.name)"
                />
              </template>
            </FormField>
          </template>
          <div class="" style="margin: 17px; margin-top: 25px">
            <div class="m-r-1 rounded-5">
              <button class="text-medium medium-font button text-color-white bg-light-green" type="submit">
                Register
              </button>
            </div>
          </div>
        </form>
        <div class="hr" style="margin: 26px 10px" />
        <div class="text-center">
          <router-link to="/login">Already have an account? Log In</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormField from 'Components/FormField';
import DropDownList from 'Components/editor/DropDownList';

const fields = [
  {
    label: 'Your name',
    placeholder: 'Name',
    type: 'text',
    name: 'name',
    required: true,
    validator: value => {
      const len = 2;
      if (!value || value.length < len) {
        throw new Error(`Enter at least ${len} symbols`);
      }
      return true;
    },
  },
  {
    label: 'Your email address',
    placeholder: 'Email',
    type: 'email',
    name: 'email',
    required: true,
    validator: value => Boolean(value),
  },
  {
    label: 'Your phone number',
    placeholder: 'Phone',
    type: 'tel',
    name: 'phone',
    required: true,
    validator: value => Boolean(value),
  },
  {
    label: 'Company name',
    placeholder: 'Company',
    type: 'text',
    name: 'company',
    required: true,
    validator: value => {
      const len = 3;
      if (!value || value.length < len) {
        throw new Error(`Enter at least ${len} symbols`);
      }
      return true;
    },
  },
  {
    label: 'How did you hear about us?',
    placeholder: 'Choose your variant',
    items: [
      { text: 'Referral', value: 'Referral' },
      { text: 'Intro Call', value: 'Intro Call' },
      { text: 'Web Search', value: 'Web Search' },
      { text: 'Social media', value: 'Social media' },
      { text: 'Conference', value: 'Conference' },
      { text: 'Intro Mail', value: 'Intro Mail' },
      { text: 'Other', value: 'Other' },
    ],
    type: 'select',
    name: 'leadSource',
    required: false,
    attrs: {
      maxHeight: '400px',
    },
  },
];

export default {
  name: 'RegisterForm',
  components: { DropDownList, FormField },
  data() {
    return {
      fields,
      form: {},
      errors: [],
      failed: false,
      succeed: false,
      failMessage: '',
    };
  },
  methods: {
    onInput(name) {
      this.errors = this.errors.filter(({ id }) => id !== name);
    },
    getError(name) {
      return (this.errors.find(({ id }) => id === name) || {}).message;
    },
    async validateForm(data = {}) {
      const errors = [];
      for (const { name, validator, required, message } of fields) {
        const value = data[name];
        if (required && value === void 0) {
          errors.push({ id: name, message: message || 'This field is required' });
          continue;
        }
        if (typeof validator === 'function') {
          try {
            if (!validator(value)) {
              errors.push({ id: name, message: 'Invalid value' });
            }
          } catch (err) {
            errors.push({ id: name, message: err.message });
          }
        }
      }
      return new Promise((resolve, reject) => {
        if (errors.length) {
          reject(errors);
        } else {
          resolve(true);
        }
      });
    },
    prepareData(data = {}) {
      const { leadSource, ...rest } = data;
      return {
        ...rest,
        uiSettings: [{ leadSource }],
      };
    },
    submit() {
      this.validateForm(this.form)
        .then(() => {
          this.$client
            .register(this.prepareData(this.form))
            .then(() => {
              this.succeed = true;
            })
            .catch(err => {
              this.succeed = false;
              this.failed = true;
              let message;
              if (err.response?.data) {
                const { title, detail, error } = err.response.data;
                message = error || `${title}: ${detail}`;
              }
              this.failMessage = message || err.message || 'Something went wrong';
            });
        })
        .catch(errors => {
          this.errors = errors;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form .type-select::v-deep .input-value {
  border-color: #e5e5e5 !important;
}
</style>
