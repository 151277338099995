<template>
  <div class="container-fluid auth-page">
    <LoginForm v-if="form === 'login'" />
    <RegisterForm v-if="form === 'register'" />
  </div>
</template>

<script>
import LoginForm from 'Components/LoginForm';
import RegisterForm from 'Components/RegisterForm';

export default {
  name: 'Login',
  components: { LoginForm, RegisterForm },
  props: {
    form: {
      validator: val => ['register', 'login'].includes(val),
    },
  },
  watch: {
    form() {
      this.setBreadcrumbs();
    },
  },
  methods: {
    setBreadcrumbs() {
      const title = { register: 'Register', login: 'Sign In' }[this.form];
      title && this.$store.dispatch('setBreadcrumbs', [{ to: this.$route.path, title }]);
    },
  },
  beforeMount() {
    this.setBreadcrumbs();
  },
};
</script>

<style type="text/css" lang="scss">
.auth-page {
  .hr {
    border-bottom: 1px solid #e5e5e5;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
